.tab-center{
    margin-left: 20%;
}
.tax1img{
    position: absolute;
    left: 56% !important;   
}
.css-qbdosj-Input {
   height: 36px !important;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
   
    border-radius:25px !important;
}
.msgwidth{
width:250px !important
}

.Payimg{
    position: absolute;
    left: 66%; 
    top:16.65%;   
    }
.saltext{
margin-left: -5px !important;
}
.upim{
    margin-left: -50px !important;
width: 385px !important;
}
.hidefromall{
display: none !important;
}
.midnm{
    margin-top: 70px!important;
}
    @media screen   and (min-device-width: 1366px)  and (max-device-width: 1535px) and (-webkit-min-device-pixel-ratio: 1)  {
        .Payimg{
            position: absolute;
            left: 69% !important; 
            top:16% !important;   
            }
    }
    @media screen   and (min-device-width: 1368px)  and (max-device-width: 1436px) and (-webkit-min-device-pixel-ratio: 1)  {
        .Payimg{
            position: absolute;
            left: 76% !important; 
            top:19% !important;   
            }
    }
    @media screen   and (min-device-width: 1300px)  and (max-device-width: 1365px) and (-webkit-min-device-pixel-ratio: 1)  {
        .Payimg{
            position: absolute;
            left: 76% !important; 
            top:18.5% !important;   
            }
    }
    @media screen   and (min-device-width: 1537px)  and (max-device-width: 1590px) and (-webkit-min-device-pixel-ratio: 1)  {
        .Payimg{
            position: absolute;
            left: 73%; 
            top:18.65%;   
            }
    }
.tax1text{
    margin-left: 7%;
}
.paytext{
    margin-left: 8.5%;
}
.basetext{
    margin-left: 7% !important;
}
.basimg{
    position: absolute;
    left: 27% !important;   
}
.bastext{
    margin-left: -9% !important;   
}
.personalimg{
    position: absolute;
    left: 41% !important;   
}
.tabalign{
    margin-left: 35% !important;
}
.basepro{
position: absolute;
width: 50px !important;
height: 50px !important;
left: 595px;
top: 235px;
}





a:link {
    color: #0080FF;
}
.textbox1-works{
    position: absolute;
    width: 518px !important;
    height: 48px;
    border: 1px solid #0080FF !important;
    border-radius: 5px;
    left:280px !important;
}
.text1_work2{
    position: absolute;
    width: 70px;
    height: 16px;
    left: 650px;
    top: 229px;
    
   
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: white;
    color: #0080FF;  
}
.txt_address{
    position: absolute;
    width: 249px !important;
    height: 48px !important;
    border-radius: 10px !important;
    left:605px !important;  
    top:310px !important;  
}
.txt1_address{
    position: absolute;
    width: 249px !important;
    height: 48px !important;
    border-radius: 10px !important;
    left:876px !important;  
    top:310px !important;  
}
.txt_addr1{
    position: absolute;
    width: 249px !important;
    height: 48px !important;
    border-radius: 10px !important;
    left:605px !important;  
    top:383px !important;  
}
.txt_addr2{
    position: absolute;
    width: 249px !important;
    height: 48px !important;
    border-radius: 10px !important;
    left:876px !important;  
    top:385px !important;  
}
.txt1_addr1{
    position: absolute;
    width: 249px !important;
    height: 48px !important;
    border-radius: 10px !important;
    left:605px !important;  
    top:457px !important;  
}
.txt1_addr2{
    position: absolute;
    width: 249px !important;
    height: 48px !important;
    border-radius: 10px !important;
    left:876px !important;  
    top:455px !important;  
}
.check1_align{
    margin-left: 28% !important;
    margin-top: 2% !important;
}
.twobtn{
    width: 253px !important;
    height: 48px !important;
    margin-left:345px !important;
    border-radius: 5px;
    margin-top: 50px !important;
}
.twobtn1{
    width: 253px !important;
    height: 48px !important;
    margin-left:-10px !important;
    box-shadow: 0px 4px 12px #73A2DA;
    border-radius: 5px;
    margin-top: 50px !important;
}
.tabjob{
margin-top:20px !important;
}
@media screen   and (min-device-width: 1200px)  and (max-device-width: 1399px) and (-webkit-min-device-pixel-ratio: 1)  {
.tax1img{
left: 58.5% !important;   
}
.upim{
    margin-left: -40px !important;
width: 400px !important;
}
.personalimg{
left: 41.6% !important;   
}
.basepro{ 
left: 535px !important;
}
.textbox1-work{
left:330px !important;
}
.text_work2{
left: 650px !important;
}
.hirepos{
margin-top:145px !important;
margin-left: -88px !important;
}
.txt_addr{
left:532px !important;    
}
.txt1_addr{
left:800px !important;    
}
.txtaddr2{
left:530px !important;   
}
.nxt_btn{
left: 590px !important;
}
.basebotimg{
left: 620px !important;
}
.txtaddr21{
    position: absolute;
    width: 248px !important;
    height: 48px !important;
    border-radius: 10px !important;
    left:530px !important;   
}
.txtaddr12{
    position: absolute;
    width: 248px !important;
    height: 48px !important;
    border-radius: 10px !important;
    left:800px !important;   
}
.textbox1-works{
left:240px !important;
}
.text1_work2{
left: 600px !important;  
}
.txt_address{
left:545px !important;   
}
.txt1_address{
left:816px !important;   
}
.txt_addr1{
left:545px !important;   
}
.txt_addr2{
left:816px !important;  
}
.txt1_addr1{
left:545px !important;   
}
.txt1_addr2{
left:816px !important;    
}
.twobtn{
margin-left:315px !important;
}
}
@media screen   and (min-device-width: 800px)  and (max-device-width: 1199px) and (-webkit-min-device-pixel-ratio: 1)  {
.tax1img{
left: 66.85% !important;   
}
.Payimg{
left: 77.6% !important; 
top:16.89% !important;   
}
.personalimg{
left: 49.2% !important;   
}
.basepro{ 
left: 495px !important;
}
.textbox1-work{
left:320px !important;
}
.text_work2{
left: 630px !important;
}
.txt_addr{
left:496px !important;    
}
.txt1_addr{
left:765px !important;    
}
.txtaddr2{
left:497px !important;   
}
.nxt_btn{
left: 550px !important;
}
.basebotimg{
left: 580px !important;
}

.textbox1-works{
left:220px !important;
}
.text1_work2{
left: 550px !important;  
}
.txt_address{
left:500px !important;   
}
.txt1_address{
left:770px !important;   
}
.txt_addr1{
left:500px !important;   
}
.txt_addr2{
left:770px !important;  
}
.txt1_addr1{
left:500px !important;   
}
.txt1_addr2{
left:770px !important;    
}
.twobtn{
margin-left:285px !important;
}
}

@media screen and (min-device-width: 200px)and (max-device-width: 400px)and (-webkit-min-device-pixel-ratio: 1)  {
    .bastextmob{
        margin-right: 10px !important;
    }
}

@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {
.mobadd1{
margin-left: -150px !important;
}

.scroll-containerbasic {
    display: flex !important; 
    overflow-x: scroll !important; 
    white-space: nowrap !important; 
    -webkit-overflow-scrolling: touch !important; 
    margin-left: -60px !important;
    
  }
.bastextmob{
    margin-left: -53px !important;
}
.baswid{
    width: 40% !important;
}
.baswid1{
    width: 40% !important;
    margin-left: 80px !important;
}
.taxtick{
    margin-top: -20px !important;
    margin-left: 75% !important;
}

.mobbasictab{
margin-left: -40px !important;
}
.personalimg{
position: relative;
left: 7% !important;   
}
.bastext{
margin-left: -7% !important;   
}
.bastext{
margin-left: 1% !important;   
}
.basimg{
    position: relative;
    left: 2% !important;   
}
.saltext{
position: relative !important;
top: -100px !important;
left: 685px !important;
   
}
.salimg{
position: relative !important;
left:90% !important;
margin-top: -100px!important;     
}
.tax1img{
position: relative;
left:58% !important; 
top: -42px !important;
width: 100% !important;  
}
.Payimg{
position: relative;
left: -15% !important; 
top:-68px !important;   
}
.tax1text{
margin-left:60% !important;
margin-top: -70px !important;
}
.mobcheckicon{
position: relative !important;
left:-21% !important;
margin-top: -70px!important;
 
}
.mobcheckicon1{
position: relative;
left:88% !important; 
margin-top: -62px!important;
}
.mobcheckicon2{
position: relative;
left:88% !important; 
margin-top: -62px!important;
}
.paytext{
position: relative  !important;
left:-25% !important;
top: 1px !important;
}
.textbox1-work{
width: 324px !important;
height: 48px !important;
left: -70px !important;
top: -2px !important;
border-radius: 10px;
}
.hirepos{
margin-top:95px !important;
margin-left: -8px !important;
}
.camimg{
margin-left: -7.5% !important;
margin-top: 18.5% !important;
}
.uploadtext{
position: absolute;
margin-top: 75px !important;
}
.txt_addr{
width: 324px !important;
height: 48px !important;
left: 32px !important;
top: 565px !important;
}
.txt1_addr{
width: 324px !important;
height: 48px !important;
left: 32px !important;
top: 628px !important;
}
.txtaddr2{
width: 324px !important;
height: 48px !important;
left: 32px !important;
top: 688px !important; 
}
.mobemailtext{
margin-top: 70px !important;
}
.mobemailtext1{
margin-top: 190px !important;
}
.check_align1{
margin-left: -52% !important;
margin-top: 10px !important;
}
.check_align2{
margin-left: -40% !important;
margin-top: 8px !important;
}
.nxt_btn{
width: 324px !important;
height: 48px !important;
top: 970px !important;
left: 8px !important;
}
.basebotimg{
display: none !important;
}
.text_work2{
left: 80px !important;
top: 460px !important;
}

.textbox1-works{
width: 324px !important;
height: 48px !important;
left: -112px !important;
top: 8px !important;
}
.text1_work2{
left: 75px !important;
top: 455px !important;
}
.txt_address{
width: 324px !important;
height: 48px !important;
left: 35px !important;
top: 525px !important; 
}
.txt1_address{
width: 324px !important;
height: 48px !important;
left: 35px !important;
top: 595px !important; 
}
.txt_addr1{
width: 324px !important;
height: 48px !important;
left: 35px !important;
top: 665px !important;    
}
.txt_addr2{
width: 324px !important;
height: 48px !important;
left: 35px !important;
top: 735px !important;     
}
.txt1_addr1{
width: 324px !important;
height: 48px !important;
left: 35px !important;
top: 805px !important;    
}
.txt1_addr2{
width: 324px !important;
height: 48px !important;
left: 35px !important;
top: 875px !important;    
}
.tabjob{
margin-top: -230px !important;
left: 20px !important;
}
.tabwag{
margin-top: 110px !important;
left: 20px !important;
}
.check1_align{
margin-left: -10% !important;
margin-top: 45% !important;
}
.twobtn{
width: 153px !important;
margin-left:-35px !important;
margin-top: 30px !important;
}
.twobtn1{
width: 153px !important;
margin-left:-20px !important;
margin-top: 30px !important;
}
.slider1 {
height: 50px;
display: flex;
overflow-x: auto;
  }
.slide1 { 
flex-shrink: 0;
 }
 
}
@media screen and (min-device-width: 600px)and (max-device-width: 1024px)and (-webkit-min-device-pixel-ratio: 1)  {
.basimg{
    position: absolute;
    left: 2% !important;   
}
.baswid{
    width: 40% !important;
    margin-left: 30px !important;
}
.baswid1{
    width: 40% !important;
    margin-left: 190px !important;
}
.bastext{
    margin-left: -7% !important;   
}

.tax1text{
    margin-left: 0%;
}
.personalimg{
    position: absolute;
    left: 20% !important;   
}
.basetext{
    margin-left: 6% !important;
}
.tax1img{
    position: absolute;
    left: 37% !important;   
}
.Payimg{
    position: absolute;
    left: 49% !important; 
    top:12.2% !important;   
    }
.paytext{
    margin-left: 7% !important;
}
.saltext{
    margin-left: -15px !important;
    }
    .tab-center_{
        margin-left: 5% !important;
        font-size: x-small !important;
    }
    .textbox1-work{
        width: 324px !important;
        height: 48px !important;
        left: 138px !important;
        top: 1px !important;
        border-radius: 10px;
        }
.hirepos{
margin-top:68px !important;
margin-left: 5px !important;
color: gray;
}
        .camimg{
        margin-left: 20.5% !important;
        margin-top: 5.5% !important;
        }
       
        .txt_addr{
        width: 324px !important;
        height: 48px !important;
        left: 224px !important;
        top: 298px !important;
        }
        .txt1_addr{
        width: 324px !important;
        height: 48px !important;
        left: 224px !important;
        top: 368px !important;
        }
        .txtaddr2{
        width: 324px !important;
        height: 48px !important;
        left: 224px !important;
        top: 438px !important; 
        }
        .mobemailtext{
        margin-top: 110px !important;
        }
        .mobemailtext1{
        margin-top: 180px !important;
        }
        .check_align1{
        margin-left: 3% !important;
        margin-top: -12px !important;
        }
        .check_align2{
        position: absolute;
        margin-left: 3% !important;
        margin-top: -16px !important;
        }
        .nxt_btn{
        width: 224px !important;
        height: 48px !important;
        top: 730px !important;
        left: 258px !important;
        }
        .basebotimg{
        display: none !important;
        }
        .text_work2{
        left: 235px !important;
        top:222px !important;
        }
        
        .textbox1-works{
        width: 324px !important;
        height: 48px !important;
        left: 138px !important;
        top: 18px !important;
        }
        .text1_work2{
        left: 255px !important;
        top: 235px !important;
        }
        .txt_address{
        width: 324px !important;
        height: 48px !important;
        left: 238px !important;
        top: 305px !important; 
        }
        .txt1_address{
        width: 324px !important;
        height: 48px !important;
        left: 238px !important;
        top: 365px !important; 
        }
        .txt_addr1{
        width: 324px !important;
        height: 48px !important;
        left: 238px !important;
        top: 425px !important;    
        }
        .txt_addr2{
        width: 324px !important;
        height: 48px !important;
        left: 238px !important;
        top: 485px !important;     
        }
        .txt1_addr1{
        width: 324px !important;
        height: 48px !important;
        left: 238px !important;
        top: 545px !important;    
        }
        .txt1_addr2{
        width: 324px !important;
        height: 48px !important;
        left: 238px !important;
        top: 605px !important;    
        }
        .check1_align{
        margin-left: 35% !important;
        margin-top: 20% !important;
        }
        .twobtn{
        width: 153px !important;
        margin-left:213px !important;
        margin-top: 30px !important;
        }
        .twobtn1{
        width: 153px !important;
        margin-left:-05px !important;
        margin-top: 30px !important;
        }
        .basepro{
            position: absolute;
            width: 50px !important;
            height: 50px !important;
            left: 150px;
            top: 235px;
            }
            .taxgap{
                margin-left: -5px !important;
            }
            .tax1text{
                margin-left: 2.5%;
            }
.tabjob{
margin-top: -200px !important;
}
.tabwag{
margin-top: 110px !important;
}
}


.text_box_size{
    height: 48px !important;
    border-radius: 10px !important;
}
.text_check_mid {
    font-size: 13.5px !important;
}
.basetext_box{
    height: 48px !important;
    border-radius: 5px !important;
}