.repsmallcard {
    
    height: 218px !important;
    border: 1px solid #D1D1D1 !important;
    border-radius: 10px !important;
}
.repsmallcard:hover {
    border-color: #007bff !important;
  }

  @media screen   and (min-device-width: 600px)  and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)  {


.downtop{

    margin-top: -34px !important;
}

.timecard {
    height: 150px !important;
    box-shadow: 0px 4px 20px #DBDBDB !important;
    border-radius: 10px !important;
}

.test{

    margin-left: 22px !important;
}

.test2{

    margin-left: 32px !important;
}

.calenright{

    margin-left: 72px !important;
}
.benifits{

    margin-left: 100px !important;
}

.forgrght{

    margin-left: -44px !important;

}

.viewtop{

    margin-top: 90px !important;
}

.viewtop1{
    margin-top: 50px !important;
}

.demosize {
    width: 145% !important;
    height: 35% !important;
    margin-left: -240px !important;
}

.wanttxt {
    position: absolute !important;
    margin-left: 10px !important;
    top: 60px !important;
}

.downsize {
    width: auto !important;
    height: 480px !important;
    overflow-y: auto;
}

.settb{
    margin-left: -60px !important;
 }

 .skipleft{

    margin-left: -220px !important;
 }

 .bookleft{

    margin-left: -400px !important;
 }
.searchic{

    margin-left: 615px !important;
}


.comleft{

    margin-left: -64px !important;


}

.comleft2{

    margin-left: -44px !important;


}

.threedotrig{

    margin-left:70px !important;
}

.canleft{

    margin-left: 52px !important;
}

.clndright{

    margin-left: 263% !important;
}

.cardtab{
     
    margin-left: -400px !important;
    margin-top: 170px !important;
    
}

.signupleft{
        margin-left: -80px !important;
        
}

.rbc-button-link {
    color: #4EB4E6 !important;
    background: none !important;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    -webkit-user-select: text;
    user-select: text;

}
.rbc-event, .rbc-day-slot .rbc-background-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
   color:#4EB4E6 !important;
background-color: transparent !important;


    padding: 2px 5px;
 
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
}

.rbc-event {
    
    text-align: center !important;
}
.pytextali {
    position: absolute;
    left: -8px !important;
}

.companynamesize{
    font-size: 10px !important;
}
 }