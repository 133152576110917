.loginhead {
    margin-top: -1% !important;
    height: 60px !important;
}
.login_head {
    margin-top: -1% !important;
    height: 60px !important;
    background-color: white;
   
}
.dropdown-item{
   
   background-color: white !important;
}
.fixs {
  position: fixed !important; 
  top: 0; 
  width: 100%;
  z-index: 1000 !important;
}
.spnname {
    padding-left: 20px;
    position: absolute;
    width: 296px;
    height: 27px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;

    color: #212121;

}
.fix{
    position:fixed !important;
    top: -1px !important;
    background-color: white;
}

.dropleft {
    margin-left: -65% !important;
    width: 150px !important;
}

.drop_left {
    margin-left: -73px !important;
}

.badge_align {
    margin-left: 2.3% !important;
}
.txtdt{
position:absolute !important ;
margin-left: -3px !important;
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1) {
    .badge_align {
        margin-left: 2.6% !important;
    }

}

@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1) {
    .fix{
    margin-left: -14px !important;
    }
    
    .mobimg{
        width: 200px !important;
        height: 70px !important;
        margin-top: -15% !important;
        margin-left: 20% !important;
    }
}

@media screen and (min-device-width: 600px)and (max-device-width: 1024px)and (-webkit-min-device-pixel-ratio: 1) {
    .prof_l{
        margin-left: -150px !important;
       top: 8px !important;
        position: absolute;
        width: 150px !important;
    }
    .prof_2{
        margin-top: 10px !important;
        margin-left: 70px !important;
        position: absolute;
        width: 100px !important;
    }
    .prof_3{
        margin-top: -05px !important;
        margin-left: 100px !important;
        position: absolute;
        width: 100px !important;
    }
    .head_side{
        position: fixed!important;
        top: 10px !important;
        left: 679px !important;
            }
            .dropleft {
                margin-top: 0px !important;
                margin-left: -80% !important;
                position: absolute !important
            }
            .drop_left {
                margin-left: -255% !important;
            }
            .btnc{
width: 200px !important;
}
.tabbelleft{
    margin-top: 2.5% !important;
    margin-left: 206px !important;
}
.fix{
    margin-left: -14px !important;
    }
    .tabhedr{
        margin-left: -46px !important;
    }
    .mobimg{
        
        margin-left: 40% !important;
    }
    
}

.hg_hda{
    height: 60px !important;
    border-bottom: #212121 !important;
}
.headeritemcent{
    margin-top: 18px !important;
}
.headeritemcentset{
    margin-top: 20px !important;
}
.headeritem_cent{
    margin-top: 13px !important;
}