.paybtn{
   width: 85px !important;
   margin-left:-5px !important;
}
.popupright{
    margin-left: 65% !important;
}
.reviseclr{
    background-color: #CECECE !important;  
}
.text1bx{
    height: 48px !important;
    border-start-start-radius: 5px !important;
}
.text2bx{
    height: 48px !important;
    border-radius: 0px !important;
}
.text3bx{
    height: 48px !important;
    border-start-end-radius:5px !important;
}
.hebtn{
    height: 48px !important;
}
.htborder{
height: 1007px !important;
}
.rbc-toolbar{
    font-size: 12px !important;
}
.popupdrop{
width: 159px !important;
height: 36px !important;

border-radius: 3px !important;
}
.payslipclose{
margin-top: -57px !important;
margin-left: 115px !important;
}
.headfont{
font-size: 13.5px !important;
font-weight: bold !important;
}
.applybtn{
width: 103px;
height: 36px;
background: #0080FF;
border-radius: 5px;
}
.filtbtn{
width: 441px;
height: 48px;
margin-left: 30px !important;
margin-bottom: 15px !important;
}
.pastxt1
{
    box-sizing: border-box;
    position: relative;
    width: 300px !important;
    height: 46px;  
    background: #FFFFFF;
    border: 1px solid #CECECE;
    border-radius: 5px;
    left: 0px !important;
}
hireimg{
    position: absolute;
    margin-left: 150px;
}
.edit_sren{
    position:absolute !important;
    top: 80px !important;
    background-color: white !important;
    height: auto!important;
}
@media screen   and (min-device-width: 1200px)  and (max-device-width: 1499px) and (-webkit-min-device-pixel-ratio: 1)  {
.search_box {
    margin-left: 68px !important;
    width: 1020px !important;
    height: 40px !important;
}
.headfont{
    font-size: 12px !important;
    font-weight: bold !important;
    }
.text_smallvnd{
    font-size: x-small !important;
}
.filtbtn{
width: 441px;
height: 48px;
margin-left: 10px !important;
margin-bottom: 15px !important;
}
.textboxbasic_img{
    
    width: 478px !important;
    height: 32px !important;
    left: 715px !important;
    top: 460px;
    
    background: #F3F3F3;
    border: 1px solid #C4C4C4;
  
   

}
.htborder{
    height: 898px !important;
    }
    .edit_ren{

         height: 142% !important;
        
        }
.edit_ren.edit_ren-expanded {
    
    height: 145% !important;
}
.edit_sren{

    height: 42% !important;
   
   }
.edit_sren.edit_sren-expanded {

height: 45% !important;
}
}

@media screen   and (min-device-width: 800px)  and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 1)  {
.search_box {
    margin-left: 75px !important;
    width: 970px !important;
    height: 40px !important;
}
.htborder{
    height: 898px !important;
    }
    .edit_ren{

         height: 142% !important;
        
        }
.edit_ren.edit_ren-expanded {
   
    height: 145% !important;
}
.edit_sren{

    height: 142% !important;
   
   }
.edit_sren.edit_sren-expanded {

height: 145% !important;
}
    
.filtbtn{
width: 421px;
height: 48px;
margin-left: 8px !important;
margin-bottom: 15px !important;
}
}
.imps{
    width: 150px !important;
    height: 150px !important;
    opacity: 70% !important;
}
.task_card {
    width: 98% !important;
    margin-left: 60px !important;
    top: 35px !important;
    height: 600px !important;
    
}
.passwordr_card {
    width: 98% !important;
    margin-left: 60px !important;
    top: 35px !important;
    height: 600px !important;
    
}
.task_pass_card {
    width: 98% !important;
    
    top: 35px !important;
    height: 600px !important;
    
}
.btn_wid{
    width: 120px !important;
}
.btn_wids{
    width: 142px !important;
}
.center_cal{
    margin-left: 02% !important;
   width: 90% !important;
}
@media screen and (min-device-width: 200px)and (max-device-width: 599px)and (-webkit-min-device-pixel-ratio: 1)  {
.mobfilter{
margin-left: -30px !important;
}
.passwordr_card {
    width: 98% !important;
    margin-left: 0px !important;
    top: 35px !important;
    height: auto !important;
    
}
.htborder{
    height: 600px !important;
    }
.popupright{
margin-left: 2% !important;
}
.mobspace{
margin-top: 15px !important;
}
.filtbtn{
width: 312px;
height: 48px;
margin-left: 18px !important;
margin-bottom: 15px !important;
border-radius: 0px !important;
}
.edit_srene{
    position:absolute !important;
    top: 400px !important;
    background-color: white !important;
    height: auto!important;
}
}
@media screen and (min-device-width: 760px)and (max-device-width: 899px)and (-webkit-min-device-pixel-ratio: 1)  {

    .taskcard {
        width: 730px !important;
        left: -50px !important;
    
    }
    .text-_small{
    font-size: 6px !important;
    }
    }
.textboxbasic_img{
    
        position: absolute;
        width: 515px !important;
        height: 32px !important;
        left: 825px;
        top: 460px;
        
        background: #F3F3F3;
        border: 1px solid #C4C4C4;
        border-radius: 5px;
       

}
    .cal{
        position: absolute !important;
        width: 150% !important;
        
        left: -150px !important;
    }
    .react-confirm-alert-body {
        font-family: Arial, Helvetica, sans-serif;
        width: auto !important;
        padding: 30px;
        text-align: left;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
        color: #666;
    }

    .edit_ren{
        position:absolute !important;
        top: 40px !important;
        background-color: white !important;
        height: 138% !important;
    }
   
    
    .im_g {
        border-radius: 50%;
      }
